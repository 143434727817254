import React, { Component } from 'react';
import moment from 'moment';

export default class ReportFilterInterval extends Component {

	getDatasFromIntervalo = intervalo => {
		let data_inicial = null;
		let data_final = null;

		switch (intervalo) {
			case "mes_passado":
				data_inicial = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				data_final = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
				break;
			case "mes_atual":
				data_inicial = moment(new Date()).startOf('month').format('YYYY-MM-DD');
				data_final = moment(new Date()).format('YYYY-MM-DD');
				break;
			case "ultimos_30_dias":
				data_inicial = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD');
				data_final = moment(new Date()).format('YYYY-MM-DD');
				break;
			case "ultimos_7_dias":
				data_inicial = moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD');
				data_final = moment(new Date()).format('YYYY-MM-DD');
				break;
			case "ontem":
				data_inicial = moment(new Date()).subtract(1, "day").format('YYYY-MM-DD');
				data_final = moment(new Date()).subtract(1, "day").format('YYYY-MM-DD');
				break;
			case "semana_passada":
				data_inicial = moment(new Date()).subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
				data_final = moment(new Date()).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
				break;
			case "semana_atual":
				data_inicial = moment(new Date()).startOf('week').format('YYYY-MM-DD');
				data_final = moment().format('YYYY-MM-DD');
				break;
			default:
				data_inicial = moment(new Date()).subtract(7, "day").format('YYYY-MM-DD');
				data_final = moment(new Date()).format('YYYY-MM-DD');
				break;
		}

		return { data_inicial, data_final };
	};

	render() {
		return (
			<React.Fragment>
				<button disabled={this.props.disabled} className="btn btn-info btn-bold btn-filtros" onClick={() => this.props.callback(this.getDatasFromIntervalo("mes_passado"))}>Mês passado</button>
				<button disabled={this.props.disabled} className="btn btn-info btn-bold btn-filtros" onClick={() => this.props.callback(this.getDatasFromIntervalo("mes_atual"))}>Mês atual</button>
				<button disabled={this.props.disabled} className="btn btn-info btn-bold btn-filtros" onClick={() => this.props.callback(this.getDatasFromIntervalo("ultimos_7_dias"))}>Últimos 7 dias</button>
				<button disabled={this.props.disabled} className="btn btn-info btn-bold btn-filtros" onClick={() => this.props.callback(this.getDatasFromIntervalo("ultimos_30_dias"))}>Últimos 30 dias</button>
				<button disabled={this.props.disabled} className="btn btn-info btn-bold btn-filtros" onClick={() => this.props.callback(this.getDatasFromIntervalo("semana_passada"))}>Semana passada</button>
				<button disabled={this.props.disabled} className="btn btn-info btn-bold btn-filtros" onClick={() => this.props.callback(this.getDatasFromIntervalo("semana_atual"))}>Semana atual</button>
				<button disabled={this.props.disabled} className="btn btn-info btn-bold btn-filtros" onClick={() => this.props.callback(this.getDatasFromIntervalo("ontem"))}>Ontem</button>
			</React.Fragment>
		);
	}
}