import React, { useState } from "react";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import { sendEmail } from '../../crud/auth.crud';
// import Swal from 'sweetalert2';
import BetterAlerts from "../../components/BetterAlerts";

function OauthSuccess(props) {
	const [loading, setLoading] = useState(false);
	const [success_list, setSuccessList] = useState([]);
	const [error_list, setErrorList] = useState([]);


	return (
		<div className='body-reset-password' >
			<div className='card-reset-password' >
				<div style={{ display: 'flex', justifyContent: 'center' }} className='row'>
					<Link to="/" className="kt-login__logo" style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
						<img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ maxWidth: '70%', height: 'auto' }} />
					</Link>
				</div>

				<div className="row">
					<div className='col-sm-12'>
						<center><h3 style={{ marginBottom: '25px' }} className="kt-login__title">Esqueci minha senha!</h3></center>
						<center><h4 style={{ marginBottom: '40px' }} className="kt-login__subtitle">Digite seu email abaixo e enviaremos<br />um link para restaurar sua senha.</h4></center>
					</div>
				</div>
			</div>
		</div>
	);
}

export default connect(null, auth.actions)(OauthSuccess)

