export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Publishers",
				root: true,
				bullet: "dot",
				icon: "fas fa-users",
				submenu: [
					{
						title: "Usuários",
						bullet: "dot",
						page: "user"
					},
					{
						title: "Logs de visitas",
						bullet: "dot",
						page: "visit-log"
					},
				]
			},
			{
				title: "Rede de Anúncios",
				root: true,
				icon: "fas fa-chart-bar",
				submenu: [
					{
						title: "Tecnologias",
						bullet: "dot",
						page: "ad-network-category"
					},
					{
						title: "Campanhas de Pedido",
						bullet: "dot",
						page: "order-campaign"
					},
					{
						title: "Pedidos",
						bullet: "dot",
						page: "orders"
					},
					{
						title: "Redes de anúncio",
						bullet: "dot",
						page: "ad-networks"
					},
					{
						title: "Tipo",
						bullet: "dot",
						page: "ad-network-type"
					},
					{
						title: "Website",
						bullet: "dot",
						page: "website"
					},
					{
						title: "Api Log",
						bullet: "dot",
						page: "api-log"
					},
				]
			},
			{
				title: "Cotações",
				root: true,
				icon: "fas fa-money-bill",
				page: "currency-exchange",
				bullet: "dot"
			},
			{
				title: "Notificações Enviadas",
				root: true,
				icon: "fas fa-bell",
				page: "notifications-sent",
				bullet: "dot"
			},
			{
				title: "Minhas Notificações",
				root: true,
				icon: "fas fa-envelope-open",
				page: "my-notifications",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-file-alt",
				submenu: [
					{
						title: "Dados",
						bullet: "dot",
						page: "report/report01"
					},
					{
						title: "Pedidos",
						bullet: "dot",
						page: "report/report02"
					},
					{
						title: "Cotações",
						bullet: "dot",
						page: "report/report03"
					},
					{
						title: "Dados por website",
						bullet: "dot",
						page: "report/report04"
					},
					{
						title: "Financeiro",
						bullet: "dot",
						page: "report/report05"
					},

				]
			},
			{
				title: "Adicionar Dados",
				root: true,
				icon: "fas fa-server",
				page: "ad-network-report/new",
				bullet: "dot"
			},
			{
				title: "Revenue Share",
				root: true,
				icon: "fas fa-percent",
				page: "ad-network-report/revshare",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
			{
				title: "Manutenção",
				root: true,
				icon: "fas fa-wrench",
				page: "maintenance",
				bullet: "dot"
			},
		]
	}
};
