
import axios from "axios";
import { Constants } from '../utils/Constants';

export function get(id) {
	return axios.get(`${Constants.baseUrl}/ad-network-report/${id}`);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/ad-network-report`, data);
}

export function update(id, data) {
	return axios.put(`${Constants.baseUrl}/ad-network-report/${id}`, data);
}

export function destroy(id) {
	return axios.delete(`${Constants.baseUrl}/ad-network-report/${id}`);
}
