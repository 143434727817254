import axios from 'axios';

export const regexEmail = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;

export const createFormikValidation = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key]) {
			errors[key] = "O campo " + value + " é obrigatório.";
		} else if (key === 'email' && !regexEmail.test(values[key])) {
			errors[key] = 'O E-mail informado é inválido.';
		}
	}

	return errors;
};

export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}

	return true;
};

export const formatHourInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1:$2");

	input.target.value = value;

	return input;
};

export const formatCNPJInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCPFInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatDataInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");

	input.target.value = value;

	return input;
};

export const formatTelefoneInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCepInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatBRLInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

	input.target.value = formatBRL(input.target.value);

	return input;
};

export const formatUSDInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

	input.target.value = formatUSD(input.target.value);

	return input;
};

export const formatBRL = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const formatNumberWithSeparators = value => {
	return value ? value.toLocaleString('pt') : 0;
};

export const formatUSD = value => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
	if (input.target.value.length > 8) {
		var cep = input.target.value.replace(/[^0-9]/, "");

		const instance = axios.create();

		instance.get(`https://viacep.com.br/ws/${cep}/json/`, {}).then(res => {
			setFieldValue(nomeEndereco, res.data.logradouro);
			setFieldValue(nomeBairro, res.data.bairro);
			setFieldValue(nomeCidade, res.data.localidade);
			setFieldValue(nomeUf, res.data.uf);
		}).catch(err => {
		});
	}

	return formatCepInput(input);
};

export const limparMoedaUSD = (valor) => {
	valor = valor.replace('/^\p{Z}+|\p{Z}+$/u', '');
	valor = valor.replace("$", "");
	valor = valor.trim();

	return valor;
};

export const limparMoeda = (valor) => {
	valor = valor.replace("R$", "");
	valor = valor.replace(".", "");
	valor = valor.replace(",", ".");
	valor = valor.replace('/^\p{Z}+|\p{Z}+$/u', '');
	valor = valor.replace("$", "");
	valor = valor.trim();

	return valor;
};

export const listaUfs = [
	'AC',
	'AL',
	'AM',
	'AP',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MG',
	'MS',
	'MT',
	'PA',
	'PB',
	'PE',
	'PI',
	'PR',
	'RJ',
	'RN',
	'RO',
	'RR',
	'RS',
	'SC',
	'SE',
	'SP',
	'TO'
];

export const displayValueCurrency = (value) => {
	return value ? `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : "Não disponível";
};

export const formatTwoDecimalHouses = (value) => {
	let formatted = displayValueFixed(value, 2);

	return formatted == "Não disponível" ? 0 : formatted.replace('.', ',');
}

export const displayValueFixed = (value, casas) => {
	return value ? value.toFixed(casas) : "Não disponível";
};

export const displayValuePercent = (value) => {
	return value ? `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} %` : "Não disponível";
};

export const difficultyList = [
	{ key: 0, label: 'Setup' },
	{ key: 1, label: 'Beginner' },
	{ key: 2, label: 'Intermediate' },
	{ key: 3, label: 'Hard' },
];

export const getDifficultyLabel = (key) => {
	let label = '';

	difficultyList.forEach((obj, i) => {
		if (obj.key === key) {
			label = obj.label;
		};
	});

	return label;
};

export const checkIfUserHasPermission = (permissions, controller, method) => {
	let hasPermission = false;

	permissions.map(element => {
		if (element.grp_controller === controller && element.grp_method === method) {
			hasPermission = true;
		}
	});

	return hasPermission;
};

export const getStatusTypes = () => {
	return [
		'Ativo', 'Inativo'
	];
};

export const getProductType = (value) => {
	return [
		"Juicebarads", "Denakop"
	];
}

export const getAdNetworkStatusTypes = () => {
	return [
		'Em exibição', 'Pausado'
	];
};

export const getAdNetworkIsSubdomain = () => {
	return [
		'False', 'True'
	];
};

export const getUserStatusTypes = () => {
	return [
		'Ativo', 'Inativo', 'Banido'
	];
};

export const getCurrencyTypes = () => {
	return [
		'USD', 'BRL'
	];
};

