
import axios from "axios";
import { Constants } from '../utils/Constants';

export function getDataTable(events) {
	return axios.post(`${Constants.baseUrl}/website/list`, events);
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/website/${id}`);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/website`, data);
}

export function update(id, data) {
	return axios.put(`${Constants.baseUrl}/website/${id}`, data);
}

export function getSimpleList() {
	return axios.get(`${Constants.baseUrl}/website/simpleList`);
}

export function getSimpleListUser() {
	return axios.get(`${Constants.baseUrl}/website/simpleListUser`);
}

export function destroy(id) {
	return axios.delete(`${Constants.baseUrl}/website/${id}`);
}
