import axios from "axios";
import { Constants } from '../utils/Constants';

export function login(email, password) {
	return axios.post(`${Constants.baseUrl}/auth`, { email, password });
}

export function sendEmail(data) {
	return axios.post(`${Constants.url}/api/web/forgot-password`, data);
}

export function resetPassword(data) {
	return axios.post(`${Constants.baseUrl}/reset-password`, data);
}