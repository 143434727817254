import { actionTypes } from '../../app/store/ducks/auth.duck';

export function removeCSSClass(ele, cls) {
	const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
	ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
	ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
	axios.interceptors.request.use(config => {
		const { auth: { authToken } } = store.getState();

		if (authToken) {
			config.headers.Authorization = `Bearer ${authToken}`;
		}

		return config;
	},
		err => Promise.reject(err)
	);

	axios.interceptors.response.use(function (response) {
		return response;
	}, function (error) {

		if (error.response.status == 401) {
			store.dispatch({ type: actionTypes.Logout, payload: null });
		}

		// if(error.response.status == 403) {
		//   window.location.href = '/error/error-v3';
		// }

		return Promise.reject(error);
	});
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
	params:
		key <string>     : localStorage key to remove
	returns:
		<boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
	try {
		localStorage.setItem(key, "");
		localStorage.setItem(key + "_expiresIn", "");
	} catch (e) {
		console.log(
			"removeStorage: Error removing key [" +
			key +
			"] from localStorage: " +
			JSON.stringify(e)
		);
		return false;
	}
	return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
	params:
		key <string> : localStorage key
	returns:
		<string> : value of localStorage key
		null : in case of expired key or failure
 */
export function getStorage(key) {
	const now = Date.now(); //epoch time, lets deal only with integer
	// set expiration for storage
	let expiresIn = localStorage.getItem(key + "_expiresIn");
	if (expiresIn === undefined || expiresIn === null) {
		expiresIn = 0;
	}

	expiresIn = Math.abs(expiresIn);
	if (expiresIn < now) {
		// Expired
		removeStorage(key);
		return null;
	} else {
		try {
			const value = localStorage.getItem(key);
			return value;
		} catch (e) {
			console.log(
				"getStorage: Error reading key [" +
				key +
				"] from localStorage: " +
				JSON.stringify(e)
			);
			return null;
		}
	}
}
/*  setStorage: writes a key into localStorage setting a expire time
	params:
		key <string>     : localStorage key
		value <string>   : localStorage value
		expires <number> : number of seconds from now to expire the key
	returns:
		<boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
	if (expires === undefined || expires === null) {
		expires = 24 * 60 * 60; // default: seconds for 1 day
	}

	const now = Date.now(); //millisecs since epoch time, lets deal only with integer
	const schedule = now + expires * 1000;
	try {
		localStorage.setItem(key, value);
		localStorage.setItem(key + "_expiresIn", schedule);
	} catch (e) {
		console.log(
			"setStorage: Error setting key [" +
			key +
			"] in localStorage: " +
			JSON.stringify(e)
		);
		return false;
	}
	return true;
}

export function isShowMenu(title, permissions) {
	let isShow = false;

	switch (title) {
		case 'Dashboard':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getErrorLog') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getTotalizers') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getCurrencyExchange') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getCharts') isShow = true;
			});
		case 'Publishers':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserAccessLogController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Usuários':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Logs de visitas':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserAccessLogController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Rede de Anúncios':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Tecnologias':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Campanhas de Pedido':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderCampaignController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Pedidos':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Redes de anúncio':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Tipo':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Website':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Cotações':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\CurrencyExchangeController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Notificações Enviadas':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\NotificationController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Minhas Notificações':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserNotificationController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Relatórios':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport01') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport02') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport03') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport04') isShow = true;
			});
		case 'Dados':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport01') isShow = true;
			});
		case 'Pedidos':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport02') isShow = true;
			});
		case 'Impressões de clientes':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport03') isShow = true;
			});
		case 'Dados por website':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport04') isShow = true;
			});
		case 'Financeiro':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport05') isShow = true;
			});
		case 'Grupos de Acesso':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\GroupController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Manutenção':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\Http\Controllers\Web\UserMaintenanceController' && obj.grp_method === 'list') isShow = true;
			});
		case 'Adicionar Dados':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkReportController' && obj.grp_method === 'store') isShow = true;
			});
		case 'Revenue Share':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkReportController' && obj.grp_method === 'store') isShow = true;
			});
		case 'Api Log':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ApiLogController' && obj.grp_method === 'list') isShow = true;
			});
	}

	return isShow;
}