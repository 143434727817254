
import axios from "axios";
import { Constants } from '../utils/Constants';

export function getDataTable(events) {
	return axios.post(`${Constants.baseUrl}/ad-network/list`, events);
}

export function getSimpleList() {
	return axios.get(`${Constants.baseUrl}/ad-network/simpleList`);
}

export function getSimpleListAdManager() {
	return axios.get(`${Constants.baseUrl}/ad-network/simpleList/admanager`);
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/ad-network/${id}`);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/ad-network`, data);
}

export function update(id, data) {
	return axios.put(`${Constants.baseUrl}/ad-network/${id}`, data);
}

export function destroy(id) {
	return axios.delete(`${Constants.baseUrl}/ad-network/${id}`);
}

export function updateRevShare(data){
	return axios.post(`${Constants.baseUrl}/ad-network/update-revshare`, data)
}