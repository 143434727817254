import React, { Component } from "react";
import BetterBox from '../../../components/BetterBox';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import BetterSelect from '../../../components/BetterSelect';
import { LayoutSubheader } from '../../../../_metronic/layout/LayoutContext';
import { TextField, OutlinedInput, FormControl, Select } from "@material-ui/core";
import { Row, Col, Table, Modal } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import ReportFilterInterval from '../report/ReportFilterInterval';
import { getSimpleList as getWebsiteList } from '../../../crud/website.crud';
import { getSimpleList as getAdNetworkList, updateRevShare } from '../../../crud/adnetworks.crud';
import { update, destroy } from '../../../crud/adnetworkreport.crud';
import { formatBRL, getCurrencyTypes, formatUSD } from '../../../utils/Utils';
import { CSVLink } from "react-csv";
import Autocomplete from '@material-ui/lab/Autocomplete';

export default class RevShareEdit extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            loading: false,
            submitted: false,
            success: [],
            errors: [],
            filtros: {
                data_inicial: moment(new Date()).subtract(1, "day").format('YYYY-MM-DD'),
                data_final: moment(new Date()).subtract(1, "day").format('YYYY-MM-DD'),
                website_id: '',
                revenue_share: 0
            },
            website_list: [],
            ad_network_list: [],
            is_show_modal: false,
            report_edit: null,
            idx_report_edit: null,
            submitting_modal: false,
            headersCsv: [],
            dataCsv: []
        };
    }

    componentDidMount = () => {
        getWebsiteList().then((res) => {
            this.setState({
                errors: res.data.errors ? res.data.errors : [],
                website_list: res.data,
                filtros: {
                    ...this.state.filtros,
                    website_id: res.data[0]   
                }, 
            });
        });
    };

    handleCsvExport = (data) => {
        let headersCsv = [
            { label: 'Rede de anúncio', key: 'ad_network' },
            { label: 'Campanha de Pedido', key: 'order_campaign' },
            { label: 'Website', key: 'website' },
            { label: 'Data', key: 'anr_date' },
            { label: 'Valor', key: 'anr_value' },
            { label: 'CPM', key: 'anr_cpm' },
            { label: 'Impressões', key: 'anr_impressions' },
            { label: 'Moeda', key: 'anr_currency' },
        ];

        let dataCsv = [];

        data.map((obj, i) => {
            dataCsv.push({
                ad_network: obj?.ad_network?.adn_name ?? 'N/A',
                order_campaign: obj?.order_campaign?.orc_name ?? 'N/A',
                website: obj?.website?.adw_name ?? 'N/A',
                anr_date: moment(obj.anr_date).format('DD/MM/YYYY'),
                anr_value: formatBRL(obj.anr_value),
                anr_cpm: obj.anr_cpm,
                anr_impressions: obj.anr_impressions,
                anr_currency: obj.anr_currency
            });
        });

        this.setState({ headersCsv, dataCsv });
    };

    filtrar = () => {
        let filter = {
            start_date: moment(this.state.filtros.data_inicial).format('YYYY-MM-DD'),
            end_date: moment(this.state.filtros.data_final).format('YYYY-MM-DD'),
        };

        if (this.state.filtros.website_id) filter.adn_id_website = this.state.filtros.website_id.id;
        filter.anr_revshare = this.state.filtros.revenue_share || 0;
        updateRevShare(filter).then((res) => {
            this.setState({
                loading: false,
                submitted: false,
                errors: res.data.errors ? res.data.errors : [],
                data: res.data ? res.data : [],
            });
            Swal.fire('Tudo certo!', 'Revenue Share configurado com sucesso!', 'success');
        }).catch((err) => {
            Swal.fire('Ops!', 'Parece que houve um problema ao buscar o resultado. Por favor, entre em contato com o suporte.', 'warning');
        });
    };

    onSubmit = () => {
        if (this.state.filtros.data_inicial > this.state.filtros.data_final) {
            Swal.fire('Ops!', 'A data final precisa ser após a inicial.', 'warning');
            return;
        }

        if (!this.state.filtros.data_inicial || !this.state.filtros.data_final) {
            Swal.fire('Ops!', 'É necessário preencher os filtros.', 'warning');
            return;
        }

        this.setState(({ submitted: true }));
        this.filtrar();
    };

    onStartEdit = (index) => {
        this.setState({ is_show_modal: true, report_edit: { ...this.state.data[index] }, idx_report_edit: index });
    };

    onCloseModal = (index) => {
        this.setState({ is_show_modal: false, report_edit: null, idx_report_edit: null });
    };

    onFinishEditReport = () => {
        if (this.state.report_edit.anr_id_ad_network === ''
            || this.state.report_edit.anr_date === ''
            || this.state.report_edit.anr_currency === '') {
            Swal.fire('Oops', 'Os campos Rede de Anúncio, Data e Moeda são obrigatórios.', 'error');
            return;
        }

        this.setState({ submitting_modal: true });

        update(this.state.report_edit.id, this.state.report_edit).catch(err => {
            Swal.fire('Oops', 'Ocorreu um erro ao tentar excluir o registro.', 'error');
        }).then(res => {
            if (res?.data) {
                if (res?.data.errors) {
                    Swal.fire('Oops', res.data.errors[0], 'error');
                } else {
                    let data = [...this.state.data];

                    data[this.state.idx_report_edit] = res.data;

                    this.setState({ data, is_show_modal: false });
                    this.onCloseModal();
                }
            }

            this.setState({ submitting_modal: false });
        });
    };

    handleDelete = (index) => {
        let data = [...this.state.data];
        let oldRegister = { ...this.state.data[index] }

        data.splice(index, 1);

        this.setState({ data });

        destroy(oldRegister.id).catch(err => {
            Swal.fire('Oops', 'An error ocurred when trying to edit.', 'error');
        }).then(res => {
            if (res?.data) {
                if (res?.data.errors) {
                    Swal.fire('Oops', res.data.errors[0], 'error');
                    data.splice(index, 0, oldRegister);
                }
            }

            this.setState({ submitting_modal: false });
        });

    };

    render() {
        return (
            <div>
                <LayoutSubheader title={`Revenue Share`} />

                <BetterCircularProgress loading={this.state.loading}>
                    <div>
                        <BetterAlerts errors={this.state.errors} success={this.state.success} />

                        {this.state.filtros ?
                            <BetterBox title="Filtros" subtitle="Configure o Revenue Share">
                                <Row>
                                    <Col sm={5}>
                                        <label className="label-with-texfield">Website</label>
                                        <FormControl variant="outlined" className="MuiFormControl-marginNormal">
                                            <Autocomplete noOptionsText="Sem resultados." value={this.state.filtros.website_id}     
                                                disableClearable={true}
                                                onChange={(event, value) => this.setState({ filtros: { ...this.state.filtros, website_id: value } })}
                                                options={this.state.website_list}
                                                getOptionLabel={(option) => option.anw_name}
                                                renderInput={(params) => <TextField placeholder='Todos' style={{ marginTop: 0 }} {...params} margin="normal" variant="outlined" />} />
                                        </FormControl>
                                    </Col>

                                    <Col sm={5}>
                                        <label className="label-with-texfield">Revenue Share</label>
                                        <TextField type="number" value={this.state.filtros.revenue_share} onChange={(e) => this.setState({ filtros: { ...this.state.filtros, revenue_share: e.target.value } })}
                                            margin="normal" variant="outlined" />
                                    </Col>

                                    <Col sm={5}>
                                        <label className="label-with-texfield">Data Inicial *</label>
                                        <TextField type="date" value={this.state.filtros.data_inicial} onChange={(e) => this.setState({ filtros: { ...this.state.filtros, data_inicial: e.target.value } })}
                                            margin="normal" variant="outlined" />
                                    </Col>

                                    <Col sm={5}>
                                        <label className="label-with-texfield">Data Final *</label>
                                        <TextField type="date" value={this.state.filtros.data_final} onChange={(e) => this.setState({ filtros: { ...this.state.filtros, data_final: e.target.value } })}
                                            margin="normal" variant="outlined" />
                                    </Col>

                                    <Col sm={2} className="col-btn-with-input">
                                        <button disabled={this.state.submitted} className="btn btn-success btn-bold btn-with-input" onClick={this.onSubmit}>
                                            <i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Configurar
                                        </button>
                                    </Col>
                                </Row>
                            </BetterBox>
                            : null
                        }

                        {/* <BetterCircularProgress loading={this.state.submitted}>
                            <BetterBox title="Resultados">
                                {this.state.dataCsv.length > 0 ?
                                    <Row>
                                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={12}>
                                            <CSVLink style={{ marginBottom: '25px' }} data={this.state.dataCsv} headers={this.state.headers}
                                                className="btn btn-info btn-bold btn-filtros" filename={"Relatório01_Dados.csv"}>
                                                <i style={{ marginRight: '10px' }} className='fas fa-file-csv'></i>
                                                CSV
                                            </CSVLink>
                                        </Col>
                                    </Row>
                                    : null
                                }

                                <Row>
                                    {(this.state.data.length > 0) ?
                                        <Col sm={12}>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Rede de anúncio</th>
                                                        <th>Campanha de pedido</th>
                                                        <th>Website</th>
                                                        <th>Data</th>
                                                        <th>Valor</th>
                                                        <th>CPM</th>
                                                        <th>Impressões</th>
                                                        <th>Pageviews</th>
                                                        <th>Moeda</th>
                                                        <th style={{ width: '120px' }} >Opções</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.data.map((obj, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{obj.id}</td>
                                                                <td>{obj?.ad_network?.adn_name ?? 'N/A'}</td>
                                                                <td>{obj?.order_campaign?.orc_name ?? 'N/A'}</td>
                                                                <td>{obj?.website?.anw_name ?? 'N/A'}</td>
                                                                <td>{moment(obj.anr_date).format('DD/MM/YYYY')}</td>
                                                                <td>{obj.anr_value != null ? obj.anr_currency == 'USD' ? formatUSD(obj.anr_value) : formatBRL(obj.anr_value) : "Não disponível"}</td>
                                                                <td>{obj.anr_cpm != null ? obj.anr_cpm == 'USD' ? formatUSD(obj.anr_cpm) : formatBRL(obj.anr_cpm) : "Não disponível"}</td>
                                                                <td>{obj.anr_impressions ?? "Não disponível"}</td>
                                                                <td>{obj.anr_pageviews ?? "Não disponível"}</td>
                                                                <td>{obj.anr_currency}</td>
                                                                <td>
                                                                    <button onClick={() => this.onStartEdit(i)} className="btn btn-success btn-table-action"><i className="fa fa-edit"></i></button>
                                                                    <button onClick={() => this.handleDelete(i)} className="btn btn-danger btn-table-action"><i className="fas fa-trash-alt"></i></button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Col>
                                        :
                                        <Col sm={12}>
                                            <center>
                                                <h4>Nenhum resultado!</h4>
                                            </center>
                                        </Col>
                                    }
                                </Row>
                            </BetterBox>
                        </BetterCircularProgress> */}

                        <Modal dialogClassName="modalEditReport" centered show={this.state.is_show_modal}
                            onHide={() => this.onCloseModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit activity</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ backgroundColor: '#FFF' }} >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group fg-line">
                                            <BetterSelect name="anr_id_ad_network" label="Rede de Anúncio *" labelWidth={130}
                                                blankOption value={this.state.report_edit?.anr_id_ad_network}
                                                onChange={e => this.setState({ report_edit: { ...this.state.report_edit, anr_id_ad_network: e.target.value } })}>
                                                {this.state.ad_network_list.map((obj, i) => {
                                                    return <option key={i} value={obj.id}>{obj.adn_name}</option>
                                                })}
                                            </BetterSelect>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group fg-line">
                                            <TextField type="date" name="anr_date" label="Data *" margin="normal"
                                                variant="outlined" value={this.state.report_edit?.anr_date}
                                                onChange={(e) => this.setState({ report_edit: { ...this.state.report_edit, anr_date: e.target.value } })} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group fg-line">
                                            <TextField type="text" name="anr_value" label="Valor *" margin="normal"
                                                variant="outlined" value={this.state.report_edit?.anr_value}
                                                onChange={(e) => this.setState({ report_edit: { ...this.state.report_edit, anr_value: e.target.value } })} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group fg-line">
                                            <TextField type="text" name="anr_cpm" label="CPM *" margin="normal"
                                                variant="outlined" value={this.state.report_edit?.anr_cpm}
                                                onChange={(e) => this.setState({ report_edit: { ...this.state.report_edit, anr_cpm: e.target.value } })} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group fg-line">
                                            <TextField type="text" name="anr_impressions" label="Impressões *" margin="normal"
                                                variant="outlined" value={this.state.report_edit?.anr_impressions}
                                                onChange={(e) => this.setState({ report_edit: { ...this.state.report_edit, anr_impressions: e.target.value } })} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group fg-line">
                                            <TextField type="text" name="anr_pageviews" label="Pageviews" margin="normal"
                                                variant="outlined" value={this.state.report_edit?.anr_pageviews}
                                                onChange={(e) => this.setState({ report_edit: { ...this.state.report_edit, anr_pageviews: e.target.value } })} />
                                        </div>
                                    </div>


                                    <div className="col-sm-12">
                                        <div className="form-group fg-line">
                                            <TextField type="float" name="anr_revshare" label="Revenue Share" margin="normal"
                                                variant="outlined" value={this.state.report_edit?.anr_revshare}
                                                onChange={(e) => this.setState({ report_edit: { ...this.state.report_edit, anr_revshare: e.target.value } })} />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#FFF' }} >
                                <button disabled={this.state.submitting_modal} className='btn btn-md btn-danger'
                                    onClick={() => this.onCloseModal()} >
                                    <i className={`${this.state.submitting_modal ? "fas fa-sync fa-spin" : "fas fa-times"}`}></i>
                                    Cancelar
                                </button>
                                <button disabled={this.state.submitting_modal} className='btn btn-md btn-success'
                                    onClick={() => this.onFinishEditReport()}	>
                                    <i className={`${this.state.submitting_modal ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>
                                    Confirmar
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </BetterCircularProgress>
            </div>
        );
    }
}