import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import RevShareEdit from "./ad-network-report/RevShareEdit";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const UserPage = lazy(() =>
	import("./user/UserPage")
);

const VisitLogPage = lazy(() =>
	import('./visit-log/VisitLogPage')
);

const AdNetworkCategoryPage = lazy(() =>
	import('./ad-network-category/AdNetworkCategoryPage')
);

const OrderPage = lazy(() =>
	import('./order/OrderPage')
);

const OrderCampaignPage = lazy(() =>
	import('./order-campaign/OrderCampaignPage')
);

const AdNetworksPage = lazy(() =>
	import('./ad-networks/AdNetworksPage')
);

const AdNetworkTypePage = lazy(() =>
	import('./ad-network-type/AdNetworkTypePage')
);

const WebsitePage = lazy(() =>
	import('./website/WebsitePage')
);

const CurrencyExchangePage = lazy(() =>
	import('./currency-exchange/CurrencyExchangePage')
);

const NotificationPage = lazy(() =>
	import('./notification/NotificationPage')
);

const UserNotificationPage = lazy(() =>
	import('./user-notification/UserNotificationPage')
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const ReportPage = lazy(() =>
	import("./report/ReportPage")
);

const AdNetworkReportPage = lazy(() =>
	import("./ad-network-report/AdNetworkReportPage")
);

const MaintenancePage = lazy(() =>
	import("./maintenance/MaintenancePage")
);

const ApiLogPage = lazy(() =>
	import("./api-log/ApiLogPage")
);


export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{<Redirect exact from="/" to="/dashboard" />}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/user" component={UserPage} />
				<Route path="/visit-log" component={VisitLogPage} />
				<Route path="/ad-network-category" component={AdNetworkCategoryPage} />
				<Route path="/api-log" component={ApiLogPage} />
				<Route path="/orders" component={OrderPage} />
				<Route path="/order-campaign" component={OrderCampaignPage} />
				<Route path="/ad-networks" component={AdNetworksPage} />
				<Route path="/ad-network-type" component={AdNetworkTypePage} />
				<Route path="/website" component={WebsitePage} />
				<Route path="/currency-exchange" component={CurrencyExchangePage} />
				<Route path="/notifications-sent" component={NotificationPage} />
				<Route path="/my-notifications" component={UserNotificationPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/report" component={ReportPage} />
				<Route path="/maintenance" component={MaintenancePage} />
				<Route path="/ad-network-report/new" component={AdNetworkReportPage} />
				<Route path="/ad-network-report/revshare" component={RevShareEdit} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
