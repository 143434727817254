import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { Formik } from "formik";
import { Link, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import { resetPassword } from '../../crud/auth.crud';
import BetterAlerts from "../../components/BetterAlerts";

function ResetPassword({ match }) {
	const [loading, setLoading] = useState(false);
	const [success_list, setSuccessList] = useState([]);
	const [error_list, setErrorList] = useState([]);
	const { search } = useLocation();

	const regexEmail = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;

	useEffect(() => {
		const query = new URLSearchParams(search);
		let token = query.get('token');

		if (!token) {
			window.location.href = '/auth';
		}
	}, []);

	const validateForm = values => {
		let map = new Map();

		map.set('email', 'email');
		map.set('password', 'password');
		map.set('passwordConfirm', 'passwordConfirm');

		const errors = {};

		for (let [key, value] of map) {
			if (!values[key]) {
				errors[key] = "O campo " + value + " é obrigatório.";
			} else if (key === 'email' && !regexEmail.test(values[key])) {
				errors[key] = 'O E-mail informado é inválido.';
			}
		}

		return errors;
	};

	const onSubmit = values => {
		const query = new URLSearchParams(search);
		let token = query.get('token');

		if (values.password.length < 6) {
			setErrorList(['A senha precisa ter pelo menos 6 caracteres.']);
			return;
		}

		if (values.password !== values.passwordConfirm) {
			setErrorList(['As senhas informadas não são iguais.']);
			return;
		}

		setLoading(true);

		let data = {
			token: token,
			email: values.email,
			password: values.password,
			password_confirmation: values.passwordConfirm
		}

		resetPassword(data).then(res => {
			if (res?.data?.errors) {
				setErrorList([res.data.errors[0]]);
			} else {
				setSuccessList(['Senha alterada com sucesso.']);
				setTimeout(() => {
					window.location.href = '/auth';
				}, 2000);
			}

			setLoading(false);
		}).catch(() => {
			setErrorList(['Parece que houve um problema ao enviar seu email. Tente novamente.']);
		});
	};

	const getInitialValues = () => {
		return {
			email: '',
			password: '',
			passwordConfirm: '',
		};
	}

	return (
		<div className='body-reset-password' >
			<div className='card-reset-password' >
				<Formik initialValues={getInitialValues()} validate={values => validateForm(values)} onSubmit={(values) => onSubmit(values)}>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
						<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
							<div style={{ display: 'flex', justifyContent: 'center' }} className='row'>
								<Link to="/" className="kt-login__logo" style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
									<img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ maxWidth: '70%', height: 'auto' }} />
								</Link>
							</div>

							<div className="row">
								<div className='col-sm-12'>
									<center><h3 style={{ marginBottom: '25px' }} className="kt-login__title">Altere sua senha!</h3></center>
									<center><h4 style={{ marginBottom: '40px' }} className="kt-login__subtitle">Digite seu email abaixo<br />e uma nova senha para sua conta.</h4></center>
								</div>

								<div style={{ marginBottom: '25px' }} className='col-sm-12'>
									<BetterAlerts errors={error_list} success={success_list} />
								</div>

								<div className="col-sm-12">
									<div className="form-group fg-line">
										<TextField name="email" label="Email *" margin="normal" variant="outlined"
											onBlur={handleBlur} onChange={handleChange} value={values.email}
											helperText={touched.email && errors.email} type='email'
											error={Boolean(touched.email && errors.email)} />
									</div>
								</div>

								<div className="col-sm-12">
									<div className="form-group fg-line">
										<TextField name="password" label="Senha *" margin="normal" variant="outlined"
											onBlur={handleBlur} onChange={handleChange} value={values.password}
											helperText={touched.password && errors.password} type='password'
											error={Boolean(touched.password && errors.password)} />
									</div>
								</div>

								<div className="col-sm-12">
									<div className="form-group fg-line">
										<TextField name="passwordConfirm" label="Confirmar Senha *" margin="normal" variant="outlined"
											onBlur={handleBlur} onChange={handleChange} value={values.passwordConfirm}
											helperText={touched.passwordConfirm && errors.passwordConfirm} type='password'
											error={Boolean(touched.passwordConfirm && errors.passwordConfirm)} />
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<center>
										<button type="submit" disabled={loading} className="btn btn-success btn-bold">
											<i className={`margin-icon ${loading ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Confirmar
										</button>
									</center>
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
}

export default connect(null, auth.actions)(ResetPassword)

